/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { restore } from "api/actions/sketch/history";
import { updateCooktop } from "api/actions/sketch/cooktop";
import { createRestorePoint } from "api/actions/history";
import { updateLocalCooktop } from "api/actions/sketch/cooktop";
import { markQuoteChanged } from "api/actions/quotes/quote";
/// TYPES ///
import { CutoutError } from "api/types/sketch";
import { Counter } from "api/types/sketch";
import { Cooktop } from "api/types/sketch";
/// COMPONENTS ///
import SaveCooktop from "components/quote/drawer/cooktop";
/// FUNCTIONS ///
import { getCounterByUuid } from "functions/sketch";

interface Props {
  selected_cooktop: Cooktop,
  setSelectedCooktop: (cooktop: Cooktop | null) => void,
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function UpdateStoreCooktop({
  selected_cooktop,
  setSelectedCooktop,
  open,
  setOpen
}: Props) {
  const dispatch = useAppDispatch();
  const { counters } = useAppSelector((state) => state.sketch);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const { cut_shape_history } = useAppSelector((state) => state.history);
  const { counter_history } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);
  const [cooktop, setCooktop] = useState<Cooktop>({});
  const [error, setError] = useState<CutoutError>({});

  useEffect(() => {
    if (open && selected_cooktop) {
      setCooktop(selected_cooktop);
    }
  }, [open]);

  const update = (cooktop: Cooktop) => {
    dispatch(updateLocalCooktop(cooktop));
    setCooktop(cooktop);
  }

  const cancel = () => {
    dispatch(restore(counter_history[history_position], cut_shape_history[history_position]));
    setOpen(false);
    setError({});
  }

  const save = () => {
    let error: CutoutError = {};
    if (!cooktop?.length) {
      error.length_error = "Length must be greater than 0";
      error.length_has_error = true;
    }

    if (!cooktop?.width) {
      error.width_error = "Width must be greater than 0";
      error.width_has_error = true;
    }

    if (error.width_error || error.length_error) {
      setError(error);
      return;
    }

    dispatch(updateCooktop(cooktop, cooktop.id));
    dispatch(markQuoteChanged());

    const new_counter: Counter = getCounterByUuid(cooktop.counter_uuid, counters);
    dispatch(createRestorePoint(
      [
        ...counters.filter(c => c.uuid !== new_counter.uuid),
        { ...new_counter, cooktops: [...new_counter.cooktops.filter(c => c.uuid !== cooktop.uuid), cooktop] }
      ],
      cut_shapes,
      "Change Sink"
    ));
    setSelectedCooktop(null);
    setOpen(false);
  }


  return (
    <SaveCooktop
      open={open}
      setOpen={setOpen}
      num_burners={cooktop?.num_burners ?? 0}
      setNumBurners={(num_burners => update({ ...cooktop, num_burners }))}
      length={cooktop?.length ?? 30}
      setLength={length => update({ ...cooktop, length })}
      length_error={error?.length_error ?? ""}
      length_has_error={error?.length_has_error ?? false}
      width={cooktop?.width ?? 26}
      setWidth={width => update({ ...cooktop, width })}
      width_error={error?.width_error ?? ""}
      width_has_error={error?.width_has_error ?? false}
      save={save}
      cancel={cancel}
      title="Update Cooktop Cutout" />
  );
}
