import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";

export enum ActionType {
  SHOP_UNDO = "SHOP_UNDO",
  SHOP_REDO = "SHOP_REDO",
  CREATE_SHOP_HISTORY_POINT = "CREATE_SHOP_HISTORY_POINT",
  SET_SHOP_COUNTERS = "SET_SHOP_COUNTERS",
  SET_SHOP_CUT_SHAPES = "SET_SHOP_CUT_SHAPES",
  SHOP_HISTORY_ACTION_SUCCESS = "SHOP_HISTORY_ACTION_SUCCESS",
  SHOP_HISTORY_UPDATING = "SHOP_HISTORY_UPDATING",
  SHOP_HISTORY_FAIL = "SHOP_HISTORY_FAIL",
  REPLACE_SHOP_RESTORE_POINT = "REPLACE_SHOP_RESTORE_POINT",
  SET_SHOP_HISTORY_WARNING = "SET_SHOP_HISTORY_WARNING",
  CLEAR_SHOP_HISTORY = "CLEAR_SHOP_HISTORY"
}

interface IUndo {
  type: ActionType.SHOP_UNDO
}

interface IRedo {
  type: ActionType.SHOP_REDO
}

interface ISetCounters {
  type: ActionType.SET_SHOP_COUNTERS,
  payload: IShopCounter[]
}

interface ISetCutShapes {
  type: ActionType.SET_SHOP_CUT_SHAPES,
  payload: IShopCutShape[]
}

interface ICreateRestorePoint {
  type: ActionType.CREATE_SHOP_HISTORY_POINT,
  counters: IShopCounter[],
  cut_shapes: IShopCutShape[],
  description: string
}

interface IHistoryActionSuccess {
  type: ActionType.SHOP_HISTORY_ACTION_SUCCESS
}

interface IHistoryUpdating {
  type: ActionType.SHOP_HISTORY_UPDATING
}

interface IHistoryFail {
  type: ActionType.SHOP_HISTORY_FAIL,
  payload: string
}

interface IReplaceRestorePoint {
  type: ActionType.REPLACE_SHOP_RESTORE_POINT,
  cut_shapes: IShopCutShape[]
}

interface ISetHistoryWarning {
  type: ActionType.SET_SHOP_HISTORY_WARNING,
  open: boolean
}

interface IClearHistory {
  type: ActionType.CLEAR_SHOP_HISTORY
}

export type Action =
  IUndo |
  IRedo |
  ISetCounters |
  ISetCutShapes |
  ICreateRestorePoint |
  IHistoryActionSuccess |
  IHistoryUpdating |
  IHistoryFail |
  IReplaceRestorePoint |
  ISetHistoryWarning |
  IClearHistory;
