/// TYPES ///
import { Area } from "api/types/quote";
import { Counter } from "api/types/sketch";
import { Label } from "api/types/sketch";
import { Quote } from "api/types/quote";
import { User } from "api/types/authentication";
/// COMPONENTS ///
import POPrintHeader from "components/universal/print/header/po";
import PrintColorOptions from "components/universal/print/color_option";
import PrintLineItems from "components/universal/print/line_items";
import PrintSummaryHeaders from "components/universal/print/summary_headers";
import PrintSummary from "components/universal/print/summary";
import PrintTerms from "components/universal/print/terms";
import PrintSignature from "components/universal/print/signature";
import QuotePrintAreas from "components/universal/diagrams/print_counter/areas";
/// PDF ///
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
/// IMAGE ///
import Logo from "static/logo_icon.png";
/// FUNCTIONS ///
import { containsColorOption } from "../color_options/validate";

interface Props {
  user: User,
  quote: Quote,
  areas: Area[],
  counters: Counter[],
  labels: Label[],
  logo: string
}

export default function QuotePrintPreviewBody({ areas, counters, labels, logo, quote, user }: Props) {
  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "40px",
      paddingBottom: "40px"
    },
    title: {
      fontWeight: "bold",
      fontSize: 14
    },
    body2: {
      fontSize: 14
    },
    logo: {
      height: "40px",
      width: "80px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "25px",
      display: "flex",
      flexDirection: "row"
    },
    font: {
      fontFamily: "Montserrat"
    },
    footer_image: {
      position: "absolute",
      right: 10,
      bottom: 5,
      height: "20px",
      width: "20px"
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <POPrintHeader
          title="ORDER ESTIMATE"
          number={quote?.estimate_number ?? ""}
          logo_url={logo ? logo : ""}
          vendor_name={`${quote?.customer_data?.first_name ?? ""} ${quote?.customer_data?.last_name ?? ""}`}
          vendor_address={quote?.customer_data?.address ?? ""}
          vendor_city={quote?.customer_data?.city ?? ""}
          vendor_province={quote?.customer_data?.province ?? ""}
          vendor_postal_code={quote?.customer_data?.postal_code ?? ""}
          vendor_phone_number={quote?.customer_data?.phone_number ?? ""}
          vendor_email={quote?.customer_data?.email ?? ""}
          bill_to_name={user?.business_name ?? ""}
          bill_to_address={user?.address ?? ""}
          bill_to_city={user?.city ?? ""}
          bill_to_province={user?.province ?? ""}
          bill_to_postal_code={user?.postal_code ?? ""}
          bill_to_phone_number={user?.phone_number ?? ""}
          bill_to_email={user?.email ?? ""}
          job_site_address={quote?.job_data?.address ?? ""}
          job_site_city={quote?.job_data?.city ?? ""}
          job_site_province={quote?.job_data?.province ?? ""}
          job_site_postal_code={quote?.job_data?.postal_code ?? ""}
          job_site_phone_number={quote?.job_data?.phone_number ?? ""}
          job_site_email={quote?.job_data?.email ?? ""}
          date={quote?.date_created ?? ""} />
        <PrintSummaryHeaders />
        <PrintLineItems areas={areas ?? []} />
        <PrintSummary
          total={quote?.total ?? 0}
          tax_name={quote?.tax_name ?? "Tax"}
          tax={quote?.tax ?? 0}
          second_tax_name={quote?.second_tax_name ?? "Tax2"}
          second_tax={quote?.second_tax ?? 0}
          discount={quote?.discount ?? 0} />
        {
          containsColorOption(areas) ?
            <PrintColorOptions areas={areas} /> :
            null
        }
        <PrintTerms payment_terms={user?.payment_terms ?? ""} />
        <QuotePrintAreas counters={counters} areas={areas} labels={labels} />
        <PrintSignature signature={quote?.signature_image} name={`${quote?.customer_data?.first_name ?? ""} ${quote?.customer_data?.last_name ?? ""}`} date={quote?.signature_date} />
        <Image src={Logo} style={styles.footer_image} fixed />
      </Page>
    </Document>
  );
}
