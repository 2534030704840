/// EVENT ///
import { SyntheticEvent } from "react";
import { MouseEvent as ReactMouseEvent } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
import useMousePosition from "hooks/mouse_position";
import useDraw from "hooks/draw";
/// ACTIONS ///
import { createLabel } from "api/actions/sketch/label";
import { deleteLabel } from "api/actions/sketch/label";
import { updateLabel } from "api/actions/sketch/label";
import { updateLocalLabel } from "api/actions/sketch/label";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { updateCounter } from "api/actions/sketch/counter";
/// HANDLER ///
import { handleCreateCounter } from "handler/counter/create";
import { handleDeleteCounter } from "handler/counter/delete";
import { handleRotateCounter } from "handler/counter/rotate";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Label } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { HighlightData } from "types/draw";
import { MouseData } from "types/mouse_position";
import { Vector2 } from "api/types/sketch";
/// MUI ///
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import StoreAreaBar from "../bar/area";
import CreateStoreArea from "../dialog/area/create";
import LabelMenu from "components/quote/menu/label";
import UpdateStoreLabel from "../dialog/label/update";
import SketchMenu from "components/quote/menu/sketch";
import UpdateStoreArea from "../drawer/update_area";
import UpdateStoreEdge from "../drawer/update_edge";
/// STYLES ///
import { sketch_style } from "styles/sketch";
import { canvasStyle } from "styles/sketch";
/// FUNCTIONS ///
import { checkCounterClicked } from "functions/sketch/check/counter";
import { checkIndicatorSelected } from "functions/sketch/check/indicator";
import { getCounterByUuid } from "functions/sketch/get/counter";
import { getCornerByUuid } from "functions/sketch/get/corner";
import { duplicateCounter } from "functions/sketch/duplicate/counter";
import { getMouseAtZoom } from "functions/sketch";
import { getFirstSlabInAreas } from "functions/sketch";
import { moveVector } from "functions/sketch/move/vector";
import { updateCorner } from "functions/sketch";
/// VALUES ///
import { getCopyMessage } from "values/text";

interface Props {
  index: number,
  zoom: number,
  current_area: number,
  setCurrentArea: (area: number) => void,
  copying: boolean,
  setCopying: (copying: boolean) => void
}

export default function StoreEdges({
  index,
  zoom,
  current_area,
  setCurrentArea,
  copying,
  setCopying
}: Props) {
  const dispatch = useAppDispatch();
  const [selected_counter, setSelectedCounter] = useState<Counter | null>(null);
  const mouse_data: MouseData = useMousePosition();
  const ref = mouse_data.ref;
  const { quote } = useAppSelector((state) => state.quote);
  const { areas } = useAppSelector((state) => state.quote);
  const { counters } = useAppSelector((state) => state.sketch);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const { labels } = useAppSelector((state) => state.sketch);
  const [lifting_label, setLiftingLabel] = useState<boolean>(false);
  const [selected_label, setSelectedLabel] = useState<Label | null>(null);
  const [in_operation, setInOperation] = useState<boolean>(false);
  const [menu_open, setMenuOpen] = useState<boolean>(false);
  const [label_menu_open, setLabelMenuOpen] = useState<boolean>(false);
  const [edit_label_open, setEditLabelOpen] = useState<boolean>(false);
  const [menu_location, setMenuLocation] = useState<Vector2>({ X: 0, Y: 0 });
  const [open, setOpen] = useState<boolean>(false);
  const [area_open, setAreaOpen] = useState<boolean>(false);
  const [create_area_open, setCreateAreaOpen] = useState<boolean>(false);
  const [current_corner, setCurrentCorner] = useState<Corner | null>(null);
  const [copy, setCopy] = useState<HighlightData | null>(null);

  const highlight_data: HighlightData = useDraw(
    index,
    selected_counter,
    areas[current_area]?.uuid,
    mouse_data,
    open,
    false,
    false,
    in_operation,
    zoom,
    {
      show_edges: true,
      linked_selection: true,
      text_selectable: !open
    }
  );

  const handleEvent = (event: SyntheticEvent) => {
    const mouse_event: MouseEvent | null = event.nativeEvent instanceof MouseEvent ? event.nativeEvent as MouseEvent : null;

    const mouse: Vector2 = mouse_data.position;
    if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 0)) {
      if (copying && !copy && highlight_data?.text) {
        setCopy(highlight_data);
        setInOperation(false);
        return;
      }
      else if (copying && copy && highlight_data?.text) {
        const old_counter: Counter | null = getCounterByUuid(copy?.text?.counter_uuid, counters);
        const corner: Corner | null = getCornerByUuid(copy?.text?.corner_uuid, old_counter);
        const new_counter: Counter | null = getCounterByUuid(highlight_data?.text?.counter_uuid, counters);
        const new_corner: Corner | null = getCornerByUuid(highlight_data?.text?.corner_uuid, new_counter);
        const update_counter: Counter | null = updateCorner(new_counter, {
          ...new_corner,
          splash_height: corner.splash_height,
          edge_type: corner.edge_type,
          finished_edge_type: corner.finished_edge_type
        });
        if (update_counter) {
          dispatch(updateLocalCounter(update_counter));
          dispatch(updateCounter(update_counter));
        }
        setInOperation(false);
        return;
      }

      if (!open) {
        for (let i = 0; i < labels.length; i++) {
          if (checkIndicatorSelected(labels[i].location, getMouseAtZoom(mouse, zoom), true, 55)) {
            setSelectedLabel(labels[i]);
            setLiftingLabel(true);
            return;
          }
        }

        if (highlight_data.text && !menu_open) {
          const counter: Counter | null = getCounterByUuid(highlight_data.text.counter_uuid, counters);
          setSelectedCounter(counter);
          if (counter) {
            const corner: Corner | null = getCornerByUuid(highlight_data.text.corner_uuid, counter);
            setCurrentCorner(corner);
            setOpen(true);
          }
          return;
        }

        if (highlight_data.corner && !menu_open) {
          const counter: Counter | null = getCounterByUuid(highlight_data.corner.counter_uuid, counters);
          setSelectedCounter(counter);
          setCurrentCorner(highlight_data.corner);
          setOpen(true);
          return;
        }

        const target: HTMLDivElement = event.target as HTMLDivElement;
        if (!target.id.startsWith("menu")) {
          setMenuOpen(false);
        }

      }
    }
    else if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 2)) {
      if (copying) {
        setCopying(false);
        setCopy(null);
        setInOperation(false);
        return;
      }
      for (let i = 0; i < labels.length; i++) {
        if (checkIndicatorSelected(labels[i].location, getMouseAtZoom(mouse, zoom), true)) {
          setSelectedLabel(labels[i]);
          setMenuLocation({
            X: mouse_event.pageX,
            Y: mouse_event.pageY
          });
          setLabelMenuOpen(true);
          return;
        }
      }

      const counter: Counter | null = checkCounterClicked(counters.filter(c => c.area_uuid === areas[current_area].uuid), getMouseAtZoom(mouse, zoom));
      if (counter) {
        setSelectedCounter(counter);
        setMenuLocation(mouse_data.screen_position);
        setMenuOpen(true);
      }
    }
    else if ((mouse_event && mouse_event.type === "mouseup" && mouse_event.button === 0)) {
      if (lifting_label && selected_label) {
        const label: Label = { ...selected_label };
        const vector: Vector2 = moveVector(selected_label.location, getMouseAtZoom(mouse, zoom));
        dispatch(updateLabel({ ...label, location: vector }, label.id));
        dispatch(updateLocalLabel({ ...label, location: vector }));
        setSelectedLabel(null);
        setLiftingLabel(false);
      }
      setInOperation(false);
      return;
    }
  }

  useEffect(() => {
    if (selected_label && lifting_label) {
      const vector: Vector2 = moveVector(selected_label.location, getMouseAtZoom(mouse_data.position, zoom));
      dispatch(updateLocalLabel({ ...selected_label, location: vector }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse_data.position]);

  const deleteSelectedCounter = () => {
    setInOperation(true);
    if (selected_counter) {
      const uuid: string = selected_counter.uuid;
      const id: number | undefined = selected_counter.id;
      handleDeleteCounter(
        dispatch,
        setSelectedCounter,
        uuid,
        id,
        counters,
        cut_shapes
      );
    }
    setInOperation(false);
    setMenuOpen(false);
  }

  const rotate = (rotation: number) => {
    if (selected_counter) {
      handleRotateCounter(
        dispatch,
        setSelectedCounter,
        { ...selected_counter },
        counters,
        cut_shapes,
        rotation
      );
    }
  }

  const handleDuplicateCounter = () => {
    setInOperation(true);
    if (selected_counter) {
      const counter: Counter = duplicateCounter(selected_counter);
      const slab: Slab | null = getFirstSlabInAreas(areas, counter.area_uuid);
      handleCreateCounter(
        dispatch,
        quote.id,
        counter,
        counters,
        cut_shapes,
        counter.area_uuid,
        slab.uuid
      );
    }
    setInOperation(false);
    setMenuOpen(false);
  }

  const handleChangeAreaOpen = () => {
    setAreaOpen(true);
    setMenuOpen(false);
  }

  const handleOpenEditLabel = () => {
    setEditLabelOpen(true);
    setLabelMenuOpen(false);
  }

  const duplicateLabel = () => {
    if (selected_label) {
      dispatch(createLabel({ text: selected_label.text, area: selected_label.area, size: selected_label.size, location: { X: 450, Y: 450 }, quote: quote.id }));
    }
    setLabelMenuOpen(false);
  }

  const handleDeleteLabel = () => {
    const id: number = selected_label.id;
    dispatch(deleteLabel(id));
    setLabelMenuOpen(false);
  }

  return (
    <div>
      <StoreAreaBar current_area={current_area} setArea={setCurrentArea} />
      <Box
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        sx={sketch_style(copy ? "crosshair" : copying ? "grab" : "normal")}
        onMouseDown={(event: ReactMouseEvent) => handleEvent(event)}
        onMouseUp={(event: ReactMouseEvent) => handleEvent(event)}
        ref={ref}>
        <SketchMenu
          open={menu_open}
          menu_location={menu_location}
          changeArea={handleChangeAreaOpen}
          deleteCounter={deleteSelectedCounter}
          duplicateCounter={handleDuplicateCounter}
          rotate={rotate} />
        <LabelMenu
          open={label_menu_open}
          menu_location={menu_location}
          openEditLabel={handleOpenEditLabel}
          duplicateLabel={duplicateLabel}
          deleteLabel={handleDeleteLabel} />
        <canvas id="canvas" className={JSON.stringify(canvasStyle)} />
        <UpdateStoreEdge
          selected_counter={selected_counter}
          open={open}
          setOpen={setOpen}
          original_corner={current_corner} />
        <UpdateStoreArea
          open={area_open}
          setOpen={setAreaOpen}
          counter={selected_counter} />
        <CreateStoreArea
          open={create_area_open}
          setOpen={setCreateAreaOpen} />
        <UpdateStoreLabel
          open={edit_label_open}
          setOpen={setEditLabelOpen}
          selected_label={selected_label} />
      </Box>
      {
        copying && copy ?
          <Stack sx={{ position: "absolute", top: mouse_data.position.Y + 30, left: mouse_data.position.X + 20, zIndex: 2000, backgroundColor: "#fff", border: "1px solid #666666", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", paddingBottom: "3px" }}>
            <Typography variant="body2">
              {getCopyMessage(copy)}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 10 }} color="error">
              Right click to cancel
            </Typography>
          </Stack> :
          null
      }
    </div>
  )
}
