/// REACT ///
import { MouseEvent as ReactMouseEvent } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// DRAW HOOKS ///
import useDrawCutShapes from "hooks/draw_cut_shapes";
import useMousePosition from "hooks/mouse_position";
/// UUID ///
import { v4 } from "uuid";
/// TYPES ///
import { Text } from "api/types/sketch";
import { SlabRect } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { Counter } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { MouseData } from "types/mouse_position";
/// ACTIONS ///
import { markQuoteChanged } from "api/actions/quotes/quote";
import { updateCutShape } from "api/actions/sketch/cut_shape";
import { updateCounter } from "api/actions/sketch/counter";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { updateLocalCutShape } from "api/actions/sketch/cut_shape";
import { updateLocalCutShapesByArea } from "api/actions/sketch/cut_shape";
import { repositionLocalCutShape } from "api/actions/sketch/cut_shape/move";
import { fittedCutShapes } from "api/actions/sketch/cut_shape/api/fitted";
import { updateQuote } from "api/actions/quotes/quote";
import { updateLocalQuote } from "api/actions/quotes/quote";
//import { replaceRestorePoint } from "api/actions/history/local/replace_restore";
import { createRestorePoint } from "api/actions/history";
import { createSlab } from "api/actions/quotes/slab";
import { deleteSlab } from "api/actions/quotes/slab";
import { setSelectedCutShape } from "api/actions/sketch/cut_shape";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
/// COMPONENTS ///
import StoreAreaBar from "../bar/area";
import StoreDefineSeams from "../dialog/seam";
import SlabActions from "components/quote/buttons/slab_actions";
import CutShapeMenu from "components/quote/menu/cut_shape";
import SlabMenu from "components/quote/menu/slab";
/// ICONS ///
import { drawSlabs } from "functions/sketch";
import { getCutShapesByCounterUuid } from "functions/sketch/get";
import { getSlabByUuid } from "functions/sketch";
import { getCounterByUuid } from "functions/sketch";
import { moveCutShape } from "functions/sketch";
import { getCutShapeByUuid } from "functions/sketch";
import { rotateCutShape } from "functions/sketch";
import { pointInQuad } from "functions/sketch/math";
import { checkCutShapeClicked } from "functions/sketch/check";
import { getCenterOfCutShape } from "functions/sketch/check";
import { constructSlabRect } from "functions/sketch";
import { checkCutShapesFitted } from "functions/sketch/check/fit";
import { getFirstSlabInArea } from "functions/sketch/get/slab";
/// VALUES ///
import { canvasStyle } from "styles/sketch";
import { sketchAreaStyle } from "styles/sketch";

interface Props {
  index: number,
  current_area: number,
  setCurrentArea: (area: number) => void
}

export default function StoreSlabs({ index, current_area, setCurrentArea }: Props) {
  const dispatch = useAppDispatch();
  const mouse_data: MouseData = useMousePosition();
  const ref = mouse_data.ref;
  const [selected_counter, setSelectedCounter] = useState<Counter | null>(null);
  const [lifting, setLifting] = useState<boolean>(false);
  const [lift_point, setLiftPoint] = useState<Vector2>({ X: 0, Y: 0 });
  const { areas } = useAppSelector(state => state.quote);
  const { quote } = useAppSelector(state => state.quote);
  const { counters } = useAppSelector(state => state.sketch);
  const { cut_shapes } = useAppSelector(state => state.sketch);
  const { selected_cut_shape } = useAppSelector(state => state.sketch);
  const [menu_location, setMenuLocation] = useState<Vector2>({ X: 0, Y: 0 });
  const [cut_menu_open, setCutMenuOpen] = useState<boolean>(false);
  const [select_slab_open, setSelectSlabOpen] = useState<boolean>(false);
  const [selected_slab, setSelectedSlab] = useState<string | null>(null);
  const [seams_open, setSeamsOpen] = useState<boolean>(false);
  const [in_operation, setInOperation] = useState<boolean>(false);

  useDrawCutShapes(
    index,
    mouse_data,
    seams_open,
    current_area
  );

  useEffect(() => {
    if (ref && ref.current && index === 5) {
      const height: number = areas[current_area]?.material_length ?? 0;
      const width: number = areas[current_area]?.material_width ?? 0;

      const updated_shapes: CutShape[] = checkCutShapesFitted(
        cut_shapes.filter(item => item.area_uuid === areas[current_area].uuid),
        areas[current_area].slabs,
        width,
        height
      );
      dispatch(updateLocalCutShapesByArea(updated_shapes, areas[current_area].uuid));

      let fitted: boolean = true;

      const save_shapes: CutShape[] = [];

      for (let i = 0; i < updated_shapes.length; i++) {
        if (!fitted) {
          fitted = false;
        }

        save_shapes.push({
          slab_uuid: updated_shapes[i].slab_uuid,
          fitted: updated_shapes[i].fitted,
          uuid: updated_shapes[i].uuid
        });
      }

      if (fitted) {
        const other_area_shapes: CutShape[] = cut_shapes.filter(
          item => item.area_uuid !== areas[current_area].uuid && !item.fitted
        );

        if (other_area_shapes.length > 0) {
          fitted = false;
        }
      };

      dispatch(fittedCutShapes(save_shapes));
      dispatch(updateLocalQuote({ ...quote, fitted }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, current_area]);

  useEffect(() => {
    if (lifting && selected_cut_shape) {
      const current_cut_shape: CutShape = getCutShapeByUuid(cut_shapes, selected_cut_shape.uuid);
      const moved_shape: CutShape = moveCutShape(selected_cut_shape, current_cut_shape, lift_point, mouse_data.position);
      const height: number = areas[current_area]?.material_length ?? 0;
      const width: number = areas[current_area]?.material_width ?? 0;

      const updated_shapes: CutShape[] = checkCutShapesFitted([
        moved_shape,
        ...cut_shapes.filter(item =>
          item.uuid !== moved_shape.uuid && item.area_uuid === areas[current_area].uuid
        )
      ], areas[current_area]?.slabs, width, height);

      dispatch(updateLocalCutShapesByArea(updated_shapes, areas[current_area].uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse_data.position, lifting, dispatch, lift_point]);

  const addSlab = () => {
    const new_slab: Slab = {
      area_uuid: areas[current_area].uuid,
      number: areas[current_area].slabs[areas[current_area].slabs.length - 1].number + 1,
      uuid: v4(),
      material_color: `${areas[current_area]?.material_name}`
    };
    dispatch(createSlab(new_slab));
    dispatch(markQuoteChanged());
  }

  const deleteSelectedSlab = () => {
    if (selected_slab) {
      const slab: Slab | null = getSlabByUuid(selected_slab, areas);
      if (slab) {
        dispatch(deleteSlab(slab));
        dispatch(markQuoteChanged());
      }
    }
    setSelectSlabOpen(false);
  }

  const moveCutShapeArea = (area_uuid: string, drawing_color: number) => {
    if (selected_cut_shape) {
      const cut_shape: CutShape = { ...selected_cut_shape, area_uuid: area_uuid };
      let index: number = 0;
      for (let i = 0; i < areas.length; i++) {
        if (areas[i].uuid === area_uuid) {
          index = i;
          break;
        }
      }
      const slab: Slab = getFirstSlabInArea(areas[index]);
      if (slab) {
        cut_shape.slab_uuid = slab.uuid;
      }
      const counter: Counter | null = { ...getCounterByUuid(cut_shape.counter_uuid, counters), area_uuid, drawing_color };
      const shapes: CutShape[] = getCutShapesByCounterUuid(cut_shapes, cut_shape.counter_uuid);
      const updated_shapes: CutShape[] = [cut_shape];

      for (let i = 0; i < shapes.length; i++) {
        const new_cut_shape: CutShape = { ...shapes[i], area_uuid };
        dispatch(updateLocalCutShape(new_cut_shape));
        dispatch(updateCutShape(new_cut_shape));
        updated_shapes.push(new_cut_shape);
      }

      if (counter) {
        dispatch(updateLocalCounter(counter));
        dispatch(updateCounter(counter));
        dispatch(createRestorePoint(
          [...counters.filter(c => c.uuid !== counter.uuid), counter],
          [...cut_shapes.filter(c => c.counter_uuid !== counter.uuid), ...updated_shapes],
          "Move Counter Area"
        ));
        dispatch(markQuoteChanged());
      }
    }
  }

  const handleOpenSeams = () => {
    if (selected_cut_shape) {
      const cut_shape: CutShape = { ...selected_cut_shape };
      const counter: Counter | null = getCounterByUuid(cut_shape.counter_uuid, counters);
      if (counter) {
        setSelectedCounter(counter);
        setSeamsOpen(true);
      }
    }
  }

  const handleEvent = (event: ReactMouseEvent) => {
    event.preventDefault();
    const mouse_event: MouseEvent | null = event.nativeEvent instanceof MouseEvent ? event.nativeEvent as MouseEvent : null;
    if (in_operation) {
      return;
    }
    setInOperation(true);
    if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 0)) {
      const mouse: Vector2 = mouse_data.position;

      const cut_shape: CutShape | null = checkCutShapeClicked(
        cut_shapes.filter(cut_shape => cut_shape.area_uuid === areas[current_area].uuid),
        mouse
      );

      if (cut_shape && !cut_menu_open) {
        dispatch(setSelectedCutShape(cut_shape));
        setLiftPoint(mouse);
        setLifting(true);
      }

      if (cut_menu_open || select_slab_open) {
        const target: HTMLDivElement = event.target as HTMLDivElement;
        if (!target.id.startsWith("menu")) {
          setCutMenuOpen(false);
          setSelectSlabOpen(false);
        }
      }
    }
    else if ((mouse_event && mouse_event.type === "mouseup" && mouse_event.button === 0)) {
      if (lifting && selected_cut_shape) {
        const cut_shape: CutShape = { ...selected_cut_shape };
        const moved_shape: CutShape = getCutShapeByUuid(cut_shapes, cut_shape.uuid);
        const height: number = areas[current_area]?.material_length ?? 0;
        const width: number = areas[current_area]?.material_width ?? 0;
        const updated_shapes: CutShape[] = checkCutShapesFitted([
          moved_shape,
          ...cut_shapes.filter(item =>
            item.uuid !== moved_shape.uuid && item.area_uuid === areas[current_area].uuid
          )
        ], areas[current_area].slabs, width, height);

        dispatch(updateLocalCutShapesByArea(updated_shapes, areas[current_area].uuid));

        let fitted: boolean = true;

        const save_shapes: CutShape[] = [];

        for (let i = 0; i < updated_shapes.length; i++) {
          if (!updated_shapes[i].fitted) {
            fitted = false;
          }

          if (updated_shapes[i].uuid === moved_shape.uuid) {
            save_shapes.push({ ...moved_shape, fitted: updated_shapes[i].fitted, slab_uuid: updated_shapes[i].slab_uuid });
          }
          else {
            save_shapes.push({
              slab_uuid: updated_shapes[i].slab_uuid,
              fitted: updated_shapes[i].fitted,
              uuid: updated_shapes[i].uuid
            });
          }
        }
        if (fitted) {
          const other_area_shapes: CutShape[] = cut_shapes.filter(
            item => item.area_uuid !== areas[current_area].uuid && !item.fitted
          );

          if (other_area_shapes.length > 0) {
            fitted = false;
          }
        };

        dispatch(fittedCutShapes(save_shapes));
        dispatch(updateLocalQuote({ ...quote, fitted }));

        //dispatch(replaceRestorePoint([...history_cut_shapes.filter(c => c.uuid !== cut_shape.uuid), cut_shape]));*/
        setLifting(false);
      }
    }
    else if ((mouse_event && mouse_event.type === "mousedown" && mouse_event.button === 2)) {
      const mouse: Vector2 = mouse_data.position;
      const cut_shape: CutShape | null = checkCutShapeClicked(
        cut_shapes.filter(cut_shape => cut_shape.area_uuid === areas[current_area].uuid),
        mouse
      );
      if (cut_shape) {
        dispatch(setSelectedCutShape(cut_shape));
        setMenuLocation(mouse_data.screen_position);
        setCutMenuOpen(true);
        setInOperation(false);
        return;
      }
      const texts: Text[] = [];

      drawSlabs(areas[current_area], new Path2D(), new Path2D(), texts);
      const height: number = areas[current_area]?.material_length ?? 0;
      const width: number = areas[current_area]?.material_width ?? 0;
      for (let i = 0; i < areas[current_area].slabs.length; i++) {
        const slab_rect: SlabRect = constructSlabRect(areas[current_area].slabs[i].uuid, i, width, height);
        if (pointInQuad(
          slab_rect.A,
          slab_rect.B,
          slab_rect.C,
          slab_rect.D,
          mouse,
        )) {
          setSelectedSlab(areas[current_area].slabs[i].uuid);
          setMenuLocation(mouse_data.screen_position);
          setSelectSlabOpen(true);
          break;
        }
      }
    }
    setInOperation(false);
  }

  const pullCutShapes = () => {
    cut_shapes.forEach(cut_shape => {
      if (!cut_shape.fitted && cut_shape.area_uuid === areas[current_area].uuid) {
        const center: Vector2 = getCenterOfCutShape(cut_shape);
        dispatch(repositionLocalCutShape(cut_shape, center, { X: 800, Y: 400 }));
      }
    });
  }

  const rotate = (delta_rotation: number) => {
    if (selected_cut_shape) {
      let cut_shape: CutShape = { ...selected_cut_shape };
      const current_cut_shape: CutShape | null = getCutShapeByUuid(cut_shapes, cut_shape.uuid);
      const current_rotation: number = current_cut_shape?.rotation - cut_shape?.rotation ?? 0;
      const new_cut_shape: CutShape = rotateCutShape(cut_shape, current_rotation + delta_rotation);
      const height: number = areas[current_area]?.material_length ?? 0;
      const width: number = areas[current_area]?.material_width ?? 0;

      const updated_shapes: CutShape[] = checkCutShapesFitted(
        [
          new_cut_shape,
          ...cut_shapes.filter(cut_shape => cut_shape.area_uuid === areas[current_area].uuid && cut_shape.uuid !== new_cut_shape.uuid)
        ],
        areas[current_area].slabs,
        width,
        height
      );
      dispatch(updateLocalCutShapesByArea(updated_shapes, areas[current_area].uuid));

      let fitted: boolean = true;

      const save_shapes: CutShape[] = [];

      for (let i = 0; i < updated_shapes.length; i++) {
        if (!updated_shapes[i].fitted) {
          fitted = false;
        }

        if (updated_shapes[i].uuid === new_cut_shape.uuid) {
          save_shapes.push({ ...new_cut_shape, fitted: updated_shapes[i].fitted, slab_uuid: updated_shapes[i].slab_uuid });
        }
        else {
          save_shapes.push({
            slab_uuid: updated_shapes[i].slab_uuid,
            fitted: updated_shapes[i].fitted,
            uuid: updated_shapes[i].uuid
          });
        }
      }

      if (fitted) {
        const other_area_shapes: CutShape[] = cut_shapes.filter(
          item => item.area_uuid !== areas[current_area].uuid && !item.fitted
        );

        if (other_area_shapes.length > 0) {
          fitted = false;
        }
      };

      dispatch(fittedCutShapes(save_shapes));
      dispatch(updateLocalQuote({ ...quote, fitted }));
      //dispatch(replaceRestorePoint([...history_cut_shapes.filter(c => c.uuid !== cut_shape.uuid), new_cut_shape]));
    }
  }

  return (
    <Box onContextMenu={(e) => { e.preventDefault(); }}
      onMouseDown={(event: ReactMouseEvent) => handleEvent(event)}
      onMouseUp={(event: ReactMouseEvent) => handleEvent(event)} >
      <Box onContextMenu={(e) => { e.preventDefault(); }} sx={sketchAreaStyle} ref={ref}>
        <canvas id="canvas" className={JSON.stringify(canvasStyle)} style={{ backgroundColor: "#fafafa" }} />
      </Box>
      <StoreAreaBar current_area={current_area} setArea={setCurrentArea} />
      <CutShapeMenu
        open={cut_menu_open}
        menu_location={menu_location}
        current_area={areas[current_area].uuid}
        areas={areas}
        moveCutShape={moveCutShapeArea}
        rotateCutShape={rotate}
        defineSeams={handleOpenSeams} />
      <SlabMenu
        open={select_slab_open}
        menu_location={menu_location}
        num_slabs={areas[current_area].slabs.length}
        deleteSlab={deleteSelectedSlab} />
      <StoreDefineSeams
        open={seams_open}
        setOpen={setSeamsOpen}
        mouse_data={mouse_data}
        selected_counter={selected_counter}
        setSelectedCounter={setSelectedCounter} />
      <SlabActions pullCutShapes={pullCutShapes} addSlab={addSlab} />
    </Box>
  );
}
