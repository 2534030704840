/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateShopCounter } from "api/actions/shop_quote";
import { updateShopCutShape } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCutShape } from "api/actions/shop_quote";
import { shopRestore } from "api/actions/shop_quote/history/local";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCounter } from "api/types/shop_quote/counter";
/// COMPONENTS ///
import UpdateArea from "components/quote/drawer/update_area";
/// FUNCTIONS ///
import { getShopAreaById } from "functions/sketch/get/area";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  counter: IShopCounter
}

export default function UpdateShopArea({ open, setOpen, counter }: Props) {
  const dispatch = useAppDispatch();
  const { cut_shape_history } = useAppSelector(state => state.shop_history);
  const { counter_history } = useAppSelector(state => state.shop_history);
  const { history_position } = useAppSelector(state => state.shop_history);
  const { areas } = useAppSelector(state => state.shop_quote);
  const { cut_shapes } = useAppSelector(state => state.shop_quote);
  const [area, setArea] = useState<IShopArea>({});
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const area: IShopArea | null = getShopAreaById(areas, counter?.area);
    if (area) {
      setArea(area);
    }
  }, [areas, counter?.area]);

  const save = () => {
    if (area?.id > 0) {
      dispatch(updateShopCounter({ ...counter, area_uuid: area.uuid, area: area.id, drawing_color: area.number - 1 }));
      for (let i = 0; i < cut_shapes.length; i++) {
        if (cut_shapes[i].counter_uuid === counter.uuid) {
          dispatch(updateLocalShopCutShape({ ...cut_shapes[i], area_uuid: area.uuid, area: area.id }));
          dispatch(updateShopCutShape({ ...cut_shapes[i], area_uuid: area.uuid, area: area.id }));
        }
      }
      setOpen(false);
    }
    else {
      setError(true);
    }
  }

  const update = (area_id: number) => {
    const area: IShopArea | null = getShopAreaById(areas, area_id);
    if (area) {
      setArea(area);
      dispatch(updateLocalShopCounter({ ...counter, area_uuid: area.uuid, area: area.id, drawing_color: area.number - 1 }));
      setError(false);
    }
  }

  const close = () => {
    dispatch(shopRestore(counter_history[history_position], cut_shape_history[history_position]));
    setOpen(false);
  }

  return (
    <UpdateArea
      open={open}
      area_id={area?.id ?? 0}
      areas={areas}
      error={error}
      update={update}
      close={close}
      save={save} />
  );
}
