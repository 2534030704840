/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { mat_tile_style } from "styles/material";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
import { mat_tile_anim } from "styles/material";
/// STATIC IMAGES ///
import Quartz from "static/c_counter.png";
import UltraCompact from "static/d_counter.png";
/// VALUES ///
import { QUARTZ } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { NATURAL_STONE } from "values/material";
/// BREADCRUMBS ///
import { material_list } from "values/breadcrumbs/materials/shop";

export default function ShopMaterialDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([material_list]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <Grid container item xs={12} md={9} lg={6}>
          <PageTitle title="Materials" endProps={
            <Box display="flex" alignItems="center" flexDirection="row">
              <BaseButton text="Add Colors" icon={faPlus} clickEvent={() => setOpen(true)} />
            </Box>
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Box display="flex" flexDirection="column" gap="20px" justifyContent="center" marginTop="10px" width="100%">
            <motion.button
              initial={{ opacity: 0, right: 20 }}
              animate={{ opacity: 1, right: 0, transition: { delay: 0.2 } }}
              style={mat_tile_style}
              whileHover={mat_tile_anim}
              onClick={() => navigate(`${QUARTZ}`)}>
              <Typography variant="h6" sx={{ marginTop: "5px", marginBottom: "5px" }}>
                Quartz
              </Typography>
              <img height="250px" width="100%" style={{ objectFit: "cover" }} src={Quartz} alt="" />
            </motion.button>
            <motion.button
              initial={{ opacity: 0, right: 20 }}
              animate={{ opacity: 1, right: 0, transition: { delay: 0.6 } }}
              style={mat_tile_style}
              whileHover={mat_tile_anim}
              onClick={() => navigate(`${ULTRA_COMPACT}`)}>
              <Typography variant="h6" sx={{ marginTop: "5px", marginBottom: "5px" }}>
                Ultra Compact / Porcelain
              </Typography>
              <img height="250px" width="100%" style={{ objectFit: "cover" }} src={UltraCompact} alt="" />
            </motion.button>
            <motion.button
              initial={{ opacity: 0, right: 20 }}
              animate={{ opacity: 1, right: 0, transition: { delay: 0.6 } }}
              style={mat_tile_style}
              whileHover={mat_tile_anim}
              onClick={() => navigate(`${NATURAL_STONE}`)}>
              <Typography variant="h6" sx={{ marginTop: "5px", marginBottom: "5px" }}>
                Natural Stone
              </Typography>
              <img height="250px" width="100%" style={{ objectFit: "cover" }} src={UltraCompact} alt="" />
            </motion.button>
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
