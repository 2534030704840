/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
/// ACTIONS ///
import { Action } from "api/action_types/shop_history";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_history";

const max_history: number = 8;

interface State {
  history_position: number,
  descriptions: string[],
  current_description: string,
  history_warning_active: boolean,
  snackbar_time: number,
  counter_history: IShopCounter[][],
  cut_shape_history: IShopCutShape[][],
  updating: boolean,
  counters_loading: boolean,
  cut_shapes_loading: boolean,
  error: string
}

const initialState = {
  history_position: 0,
  descriptions: [""],
  current_description: "",
  history_warning_active: false,
  snackbar_time: 0,
  counter_history: [],
  cut_shape_history: [],
  updating: false,
  counters_loading: false,
  cut_shapes_loading: false,
  error: ""
}

export const shopHistoryReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.CLEAR_SHOP_HISTORY:
      return {
        ...state,
        history_position: 0,
        descriptions: [""],
        current_description: "",
        counter_history: [],
        cut_shape_history: [],
        error: ""
      }
    case ActionType.CREATE_SHOP_HISTORY_POINT:
      return {
        ...state,
        history_position: state.history_position + 1 > max_history ? max_history : state.history_position + 1,
        descriptions: state.history_position + 1 > max_history ?
          [...state.descriptions.slice(1, state.history_position + 1), action.description] :
          [...state.descriptions.slice(0, state.history_position + 1), action.description],
        counter_history: state.history_position + 1 > max_history ?
          [...state.counter_history.slice(1, state.history_position + 1), action.counters] :
          [...state.counter_history.slice(0, state.history_position + 1), action.counters],
        cut_shape_history: state.history_position + 1 > max_history ?
          [...state.cut_shape_history.slice(1, state.history_position + 1), action.cut_shapes] :
          [...state.cut_shape_history.slice(0, state.history_position + 1), action.cut_shapes]
      }
    case ActionType.SHOP_REDO:
      if (state.counter_history.length - 1 === state.history_position) {
        return state;
      }
      return {
        ...state,
        history_position: state.history_position + 1,
        current_description: `Redo ${state.descriptions[state.history_position + 1]}`,
        history_warning_active: true,
        snackbar_time: Math.round(new Date().getTime() / 1000)
      }
    case ActionType.SHOP_UNDO:
      if (state.history_position === 0) {
        return state;
      }
      return {
        ...state,
        history_position: state.history_position - 1,
        current_description: `Undo ${state.descriptions[state.history_position]}`,
        history_warning_active: true,
        snackbar_time: Math.round(new Date().getTime() / 1000)
      }
    case ActionType.SET_SHOP_COUNTERS:
      return {
        ...state,
        counter_history: [action.payload],
        counters_loading: false
      }
    case ActionType.SET_SHOP_CUT_SHAPES:
      return {
        ...state,
        cut_shape_history: [action.payload],
        cut_shapes_loading: false
      }
    case ActionType.REPLACE_SHOP_RESTORE_POINT:
      return {
        ...state,
        cut_shape_history: [...state.cut_shape_history.slice(0, state.history_position), action.cut_shapes]
      }
    case ActionType.SHOP_HISTORY_ACTION_SUCCESS:
      return {
        ...state,
        updating: false
      }
    case ActionType.SHOP_HISTORY_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.SET_SHOP_HISTORY_WARNING:
      return {
        ...state,
        history_warning_active: action.open,
        snackbar_time: Math.round(new Date().getTime() / 1000)
      }
    case ActionType.SHOP_HISTORY_FAIL:
      return {
        ...state,
        updating: false,
        error: action.payload
      }
    default:
      return state;
  }
}
