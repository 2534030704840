/// REDUX ///
import { Dispatch } from "redux";
/// ACTION ///
import { Action } from "api/action_types/shop_history";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_history";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";

export const createShopRestorePoint = (counters: IShopCounter[], cut_shapes: IShopCutShape[], description: string = "") => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_SHOP_HISTORY_POINT,
      counters,
      cut_shapes,
      description
    });
  }
}
