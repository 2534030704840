/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
/// ACTIONS ///
import { updateLocalShopCutShapes } from "api/actions/shop_quote";
import { updateShopCounter } from "api/actions/shop_quote";
import { createShopRestorePoint } from "api/actions/shop_history";
import { createBulkShopCutShapes } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { createCutShapes } from "functions/sketch";

export function handleUpdateShopCounter(
  dispatch: (({ ...any }) => void),
  counter: IShopCounter,
  counters: IShopCounter[],
  cut_shapes: IShopCutShape[],
  slab_uuid: string,
  update_history_message: string
) {
  dispatch(updateShopCounter(counter));
  const new_cut_shapes: IShopCutShape[] = createCutShapes(
    counter,
    counter.area_uuid,
    slab_uuid,
    counter.points,
    counter.corners,
    []
  );
  dispatch(updateLocalShopCutShapes(new_cut_shapes, counter.uuid));
  dispatch(createBulkShopCutShapes(new_cut_shapes));
  dispatch(createShopRestorePoint(
    [...counters.filter(c => c.uuid !== counter.uuid), counter],
    [...cut_shapes.filter(c => c.counter_uuid !== counter.uuid), ...new_cut_shapes],
    update_history_message
  ));
  dispatch(markShopQuoteChanged());
}
