/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// TYPES ///
import { IShopColor } from "api/types/shop_material";
import { IShopColorOption } from "api/types/shop_quote/color_option";
import { IShopMaterial } from "api/types/shop_material";
/// ACTIONS ///
import { deleteShopColorOption, updateShopColorOption } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
/// COMPONENTS ///
import MainColor from "components/quote/color_options/area/row/color";
import ShopCreateMaterial from "../../dialog/create_material";
import ShopCreateColor from "../../dialog/create_color";
/// LODASH ///
import { filter } from "lodash";
/// VALUES ///
import { QUARTZ } from "values/material";

interface Props {
  color_option: IShopColorOption,
  description: string,
}

export default function ShopColorOptionItem({ color_option, description }: Props) {
  const dispatch = useAppDispatch();
  const { colors } = useAppSelector((state) => state.shop_material);
  const { materials } = useAppSelector((state) => state.shop_material);
  const [filt_mats, setFiltMats] = useState<IShopMaterial[]>([]);
  const [filt_colors, setFiltColors] = useState<IShopColor[]>([]);
  const [selected_type, setSelectedType] = useState<string>(color_option?.material_type ?? QUARTZ);
  const [selected_material, setSelectedMaterial] = useState<number>(0);
  const [selected_color, setSelectedColor] = useState<number>(0);
  const [create_material_open, setCreateMaterialOpen] = useState<boolean>(false);
  const [create_color_open, setCreateColorOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedType(color_option?.material_type);
  }, [color_option?.material_type]);

  useEffect(() => {
    for (let i = 0; i < materials.length; i++) {
      if (materials[i].id === color_option?.material) {
        setSelectedMaterial(materials[i].id);
      }
    }
  }, [materials, color_option?.material]);

  useEffect(() => {
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].id === color_option?.color) {
        setSelectedColor(colors[i].id);
      }
    }
  }, [colors, color_option?.color]);

  useEffect(() => {
    setFiltMats(filter(materials, { "material_type": selected_type }));
  }, [setFiltMats, materials, selected_type]);

  useEffect(() => {
    setFiltColors(filter(colors, { "material": selected_material }));
  }, [colors, selected_material, setFiltColors]);

  const setCurrentColor = (id: number) => {

  }

  const setCurrentMaterial = (id: number) => {

  }

  const saveColor = (
    color_id: number,
    material_id: number,
    material_type: string,
    material_name: string,
    color_name: string,
    color_length: number,
    color_width: number
  ) => {
    dispatch(updateShopColorOption({
      ...color_option,
      color: color_id,
      material: material_id,
      material_type: material_type,
      material_name: `${material_name} ${color_name}`,
      material_length: color_length,
      material_width: color_width
    }));
    dispatch(markShopQuoteChanged());
  }

  return (
    <Fragment>
      <MainColor
        description={description}
        colors={filt_colors}
        materials={filt_mats}
        saveColor={saveColor}
        selected_type={selected_type}
        setSelectedType={setSelectedType}
        selected_material={selected_material}
        setSelectedMaterial={setSelectedMaterial}
        selected_color={selected_color}
        setSelectedColor={setSelectedColor}
        setCreateColorOpen={setCreateColorOpen}
        setCreateMaterialOpen={setCreateMaterialOpen}
        deleteColorOption={() => dispatch(deleteShopColorOption(color_option))}
        showNaturalStone />
      <ShopCreateMaterial
        open={create_material_open}
        setOpen={setCreateMaterialOpen}
        setCurrent={setCurrentMaterial} />
      <ShopCreateColor
        open={create_color_open}
        setOpen={setCreateColorOpen}
        setCurrent={setCurrentColor} />
    </Fragment>
  );
}
