/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
/// MUI COMPONENTS ///
import { ClickAwayListener } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Popper from "@mui/material/Popper";
/// COLORS ///
import { well_color } from "styles/style";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  anchorEl: HTMLDivElement,
  chooseJob: (job: IFabricationJob) => void
}

export default function JobResults({ open, setOpen, anchorEl, chooseJob }: Props) {
  const { fabrication_jobs: jobs } = useAppSelector((state) => state.end_customer);

  const choose = (job: IFabricationJob) => {
    chooseJob(job);
    setOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Popper
        sx={{ backgroundColor: well_color, zIndex: 1350 }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-start"}>
        <List>
          {
            jobs.map((job: IFabricationJob, index: number) => {
              return (
                <ListItemButton key={index} onClick={() => choose(job)}>
                  {job.geo_address}
                </ListItemButton>
              )
            })
          }
        </List>
      </Popper>
    </ClickAwayListener>
  )
}
