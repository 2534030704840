import { Dispatch } from "redux";
import { Action } from "api/action_types/history";
import { ActionType } from "api/action_types/history";

export const setHistoryWarning = (open: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_HISTORY_WARNING,
      open
    });
  }
}
