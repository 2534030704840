/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
/// ACTIONS ///
import { updateShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { createShopRestorePoint } from "api/actions/shop_history";
import { markShopQuoteChanged } from "api/actions/shop_quote";
/// COMPONENTS ///
import UpdateOutletsDrawer from "components/quote/drawer/update_outlet";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  counter: IShopCounter | null
}

export default function UpdateShopOutlets({
  open,
  setOpen,
  counter
}: Props) {
  const dispatch = useAppDispatch();
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const [outlet_cutout_count, setOutletCutoutCount] = useState<number>(0);

  useEffect(() => {
    if (open && counter) {
      setOutletCutoutCount(counter?.outlet_cutout_count);
    }
  }, [open]);

  const save = () => {
    if (counter) {
      dispatch(updateShopCounter({ ...counter, outlet_cutout_count }));
      dispatch(updateLocalShopCounter({ ...counter, outlet_cutout_count }));

      dispatch(createShopRestorePoint(
        [...counters.filter(c => c.uuid !== counter?.uuid), counter],
        cut_shapes,
        "Change Number of Outlets on Counter"
      ));
      dispatch(markShopQuoteChanged());
      close();
    }
  }

  const close = () => {
    setOpen(false);
    setOutletCutoutCount(0);
  }

  return (
    <UpdateOutletsDrawer
      open={open}
      outlet_cutout_count={outlet_cutout_count}
      setOutletCutoutCount={setOutletCutoutCount}
      close={close}
      save={save} />
  );
}
