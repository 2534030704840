import { Dispatch } from "redux";
import { Action } from "api/action_types/history";
import { ActionType } from "api/action_types/history";
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";

export const createRestorePoint = (counters: Counter[], cut_shapes: CutShape[], description: string = "") => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_HISTORY_POINT,
      counters,
      cut_shapes,
      description
    });
  }
}
