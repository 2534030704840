/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// ACTIONS ///
import { handleUpdateCounter } from "handler/counter/update";
import { createBump } from "api/actions/sketch/bump";
import { createLocalBump } from "api/actions/sketch/bump";
import { createLocalCutBump } from "api/actions/sketch/cut_bump/local/create";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { deleteLocalBump } from "api/actions/sketch/bump";
import { deleteLocalCutBump } from "api/actions/sketch/cut_bump";
import { deleteBump } from "api/actions/sketch/bump";
import { markQuoteChanged } from "api/actions/quotes/quote";
import { restore } from "api/actions/sketch/history";
/// TYPES ///
import { Bump } from "api/types/sketch";
import { Counter } from "api/types/sketch";
import { CutBump } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { CutCornerAndShape } from "api/types/sketch";
import { Line } from "api/types/sketch";
/// COMPONENTS ///
import UpdateBump from "components/quote/drawer/update_bump";
import UpdateBumpRow from "./row";
/// FUNCTIONS ///
import { getFirstSlabInAreas } from "functions/sketch";
import { getCutCornerByCornerUuid } from "functions/sketch";
/// VALUES ///
import { empty_bump } from "values/empty/sketch";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  selected_line: Line,
  setSelectedLine: (line: Line | null) => void
}

export default function UpdateStoreBump({ open, setOpen, selected_line, setSelectedLine }: Props) {
  const dispatch = useAppDispatch();
  const { cut_shape_history } = useAppSelector(state => state.history);
  const { counter_history } = useAppSelector(state => state.history);
  const { history_position } = useAppSelector(state => state.history);
  const { areas } = useAppSelector(state => state.quote);
  const { counters } = useAppSelector(state => state.sketch);
  const { cut_shapes } = useAppSelector(state => state.sketch);
  const [expanded, setExpanded] = useState<number>(-1);
  const [bumps, setBumps] = useState<Bump[]>([]);

  useEffect(() => {
    if (open && selected_line?.start.bumps) {
      setBumps(selected_line.start.bumps as Bump[]);
    }
  }, [open]);

  const cancel = () => {
    dispatch(restore(counter_history[history_position] as Counter[], cut_shape_history[history_position] as CutShape[]));
    setBumps([]);
    setOpen(false);
  }

  const save = () => {
    if (selected_line && bumps) {
      const counter: Counter = { ...selected_line.counter as Counter, bumps };
      const slab: Slab | null = getFirstSlabInAreas(areas, counter.area_uuid);
      handleUpdateCounter(
        dispatch,
        counter,
        counters,
        cut_shapes, slab.uuid,
        "Change Bumps"
      );
    }
    setOpen(false);
    setBumps([]);
  }

  const update = (bump: Bump) => {
    const new_bumps: Bump[] = [...bumps.map(b => b.uuid === bump.uuid ? bump : b)];
    const counter: Counter = {
      ...selected_line?.counter, corners: selected_line?.counter?.corners?.map(corner => corner.uuid === selected_line?.start?.uuid ?
        { ...corner, bumps: new_bumps } :
        corner
      )
    };
    dispatch(updateLocalCounter(counter));
    setBumps(new_bumps);
  }

  const add = () => {
    if (selected_line && selected_line.start) {
      const new_bump: Bump = {
        ...empty_bump,
        uuid: v4(),
        corner_uuid: selected_line?.start?.uuid,
        counter_uuid: selected_line?.start?.counter_uuid,
        corner: selected_line?.start?.id
      };
      dispatch(createLocalBump(new_bump));
      setBumps([...bumps, new_bump]);

      const corner_and_shape: CutCornerAndShape | null = getCutCornerByCornerUuid(
        new_bump.counter_uuid,
        new_bump.corner_uuid,
        cut_shapes
      );

      if (corner_and_shape) {
        const cut_bump: CutBump = {
          uuid: v4(),
          bump_uuid: new_bump.uuid,
          counter_uuid: new_bump.counter_uuid,
          cut_corner_uuid: corner_and_shape.cut_corner.uuid,
          cut_shape_uuid: corner_and_shape.cut_shape.uuid,
          bump_type: new_bump.bump_type,
          depth: new_bump.depth,
          distance: new_bump.distance,
          left_side_angle: new_bump.left_side_angle,
          right_side_angle: new_bump.right_side_angle,
          width: new_bump.width
        }
        dispatch(createBump(new_bump));
        dispatch(createLocalCutBump(new_bump, cut_bump));
      }
      dispatch(markQuoteChanged());
    }
  }

  const remove = (index: number) => {
    const bump: Bump = bumps[index];

    if (selected_line && selected_line.start) {
      setSelectedLine({ ...selected_line, start: { ...selected_line.start, bumps: [...selected_line.start.bumps.filter(b => b.uuid !== bump.uuid)] } });
    }
    if (bumps) {
      setBumps(bumps.filter(b => b.uuid !== bump.uuid));
    }

    dispatch(deleteBump(bump));
    dispatch(deleteLocalBump(bump));
    dispatch(deleteLocalCutBump(bump));
    dispatch(markQuoteChanged());
  }

  const toggleAccordion = (index: number) => {
    if (expanded === index) {
      setExpanded(-1);
    }
    else {
      setExpanded(index);
    }
  }

  return (
    <UpdateBump open={open} setOpen={setOpen} cancel={cancel} save={save} add={add}>
      {
        bumps.map((bump: Bump, index: number) => {
          return (
            <UpdateBumpRow
              index={index}
              expanded={expanded === index}
              toggle={toggleAccordion}
              bump={bump}
              cancel={cancel}
              close={() => setOpen(false)}
              save={save}
              add={add}
              update={update}
              remove={remove}
            />
          )
        })}
    </UpdateBump>
  )
}
