/// TYPES ///
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
/// ACTIONS ///
import { deleteLocalShopCounter } from "api/actions/shop_quote";
import { deleteLocalShopCutShapes } from "api/actions/shop_quote";
import { deleteShopCounter } from "api/actions/shop_quote";
import { createShopRestorePoint } from "api/actions/shop_history";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { deleteLocalCounter } from "api/actions/sketch/counter";
import { deleteLocalCutShapes } from "api/actions/sketch/cut_shape";
import { deleteCounter } from "api/actions/sketch/counter";
import { createRestorePoint } from "api/actions/history";
import { markQuoteChanged } from "api/actions/quotes/quote";

export function handleDeleteCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: Counter | null) => void,
  counter_uuid: string,
  counter_id: number,
  counters: Counter[],
  cut_shapes: CutShape[]
) {
  dispatch(deleteLocalCounter(counter_uuid));
  dispatch(deleteLocalCutShapes(counter_uuid));
  dispatch(deleteCounter(counter_uuid, counter_id));
  dispatch(createRestorePoint(
    [...counters.filter(c => c.uuid !== counter_uuid)],
    [...cut_shapes.filter(c => c.counter_uuid !== counter_uuid)],
    "Delete Counter"
  ));
  setSelectedCounter(null);
  dispatch(markQuoteChanged());
}

export function handleDeleteShopCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: IShopCounter | null) => void,
  counter_uuid: string,
  counter_id: number,
  counters: IShopCounter[],
  cut_shapes: IShopCutShape[]
) {
  dispatch(deleteLocalShopCounter(counter_uuid));
  dispatch(deleteLocalShopCutShapes(counter_uuid));
  dispatch(deleteShopCounter(counter_uuid, counter_id));
  dispatch(createShopRestorePoint(
    [...counters.filter(c => c.uuid !== counter_uuid)],
    [...cut_shapes.filter(c => c.counter_uuid !== counter_uuid)],
    "Delete Counter"
  ));
  setSelectedCounter(null);
  dispatch(markShopQuoteChanged());
}
