/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// TYPES ///
import { Counter } from "api/types/sketch";
/// ACTIONS ///
import { updateCounter } from "api/actions/sketch/counter";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { createRestorePoint } from "api/actions/history";
import { markQuoteChanged } from "api/actions/quotes/quote";
/// COMPONENTS ///
import UpdateOutletsDrawer from "components/quote/drawer/update_outlet";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  counter: Counter | null
}

export default function UpdateStoreOutlets({
  open,
  setOpen,
  counter
}: Props) {
  const dispatch = useAppDispatch();
  const { counters } = useAppSelector((state) => state.sketch);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const [outlet_cutout_count, setOutletCutoutCount] = useState<number>(0);

  useEffect(() => {
    setOutletCutoutCount(counter?.outlet_cutout_count);
  }, [counter?.id]);

  const save = () => {
    if (counter) {
      dispatch(updateCounter({ ...counter, outlet_cutout_count }));
      dispatch(updateLocalCounter({ ...counter, outlet_cutout_count }));

      dispatch(createRestorePoint(
        [...counters.filter(c => c.uuid !== counter.uuid), counter],
        cut_shapes,
        "Change Number of Outlets on Counter"
      ));
      dispatch(markQuoteChanged());
      close();
    }
  }

  const close = () => {
    setOpen(false);
    setOutletCutoutCount(0);
  }

  return (
    <UpdateOutletsDrawer
      open={open}
      outlet_cutout_count={outlet_cutout_count}
      setOutletCutoutCount={setOutletCutoutCount}
      close={close}
      save={save} />
  );
}
