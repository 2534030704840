/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTION ///
import { Action } from "api/action_types/shop_quote";
import { Action as AuthAction } from "api/action_types/authentication";
import { Action as HistoryAction } from "api/action_types/shop_history";
///ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { ActionType as HistoryActionType } from "api/action_types/shop_history";
/// URLS ///
import { url_prefix } from "settings";
import { shop_cut_shape_url } from "../../endpoints";

export const getShopCutShapes = (quote?: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction | HistoryAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SHOP_SHAPES_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${shop_cut_shape_url}/?`;

    if (quote) {
      url += `quote=${quote}&`;
    }

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SHOP_CUT_SHAPES,
        payload: data
      });

      dispatch({
        type: HistoryActionType.SET_SHOP_CUT_SHAPES,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SHOP_SHAPES_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
