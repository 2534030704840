/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
/// ACTIONS ///
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { shopRestore } from "api/actions/shop_quote/history/local";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopSlab } from "api/types/shop_quote/slab";
/// MUI ///
import UpdateHeight from "components/quote/drawer/update_height";
/// FUNCTIONS ///
import { createShopCorners } from "functions/sketch/create/shop_corner";
import { updateShopHeight } from "functions/sketch/update/shop_height";
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
import { handleUpdateShopCounter } from "handler/shop_counter/update";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  original_value: number | null,
  point_uuid: string,
  selected_counter: IShopCounter | null
}

export default function UpdateShopHeight({ open, setOpen, original_value, point_uuid, selected_counter }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const { cut_shape_history } = useAppSelector((state) => state.shop_history);
  const { counter_history } = useAppSelector((state) => state.shop_history);
  const { history_position } = useAppSelector((state) => state.shop_history);
  const ref = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string>("");
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (open && original_value) {
      setHeight(original_value);
    }
  }, [open, original_value]);

  const update = (new_height: number) => {
    setHeight(Number(new_height));
    if (isNaN(Number(new_height)) === false && selected_counter && Number(new_height) >= 1 && original_value) {
      const update_counter: IShopCounter = updateShopHeight(
        selected_counter,
        Number(new_height),
        original_value,
        point_uuid
      );
      const new_corners: IShopCorner[] = createShopCorners(update_counter.points, [...update_counter.corners], update_counter.uuid);
      dispatch(updateLocalShopCounter({ ...update_counter, corners: new_corners }));
    }
  }

  const cancel = () => {
    dispatch(shopRestore(counter_history[history_position] as IShopCounter[], cut_shape_history[history_position] as IShopCutShape[]));
    setOpen(false);
    setError("");
  }

  useEffect(() => {
    if (open && original_value) {
      setHeight(original_value);
    }
  }, [open, original_value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref !== null && ref.current !== null) {
        ref.current.focus();
        ref.current.select();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  const save = () => {
    if (!height || isNaN(Number(height)) || Number(height) < 1) {
      setError('Measurement must be larger than 1"');
      return;
    }
    if (selected_counter && height && original_value) {
      const new_counter: IShopCounter = updateShopHeight(
        selected_counter,
        Number(height),
        original_value,
        point_uuid
      );
      const new_corners: IShopCorner[] = createShopCorners(new_counter.points, [...new_counter.corners], new_counter.uuid);
      const counter: IShopCounter = { ...new_counter, corners: new_corners };
      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, counter.area_uuid);
      handleUpdateShopCounter(
        dispatch,
        counter,
        counters,
        cut_shapes,
        slab.uuid,
        "Change Counter Height"
      );
    }
    else {
      setError("An error has occurred, please refresh the page and try again.");
    }
    setOpen(false);
    setError("");
  }

  return (
    <UpdateHeight
      open={open}
      setOpen={setOpen}
      height={height}
      update={update}
      save={save}
      cancel={cancel}
      error={error} />
  );
}
