/// REDUX ///
import { Dispatch } from "redux";
/// ACTION ///
import { Action } from "api/action_types/shop_history";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_history";

export const shopRedo = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SHOP_REDO
    });
  }
}
