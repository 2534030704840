/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
import { IShopColor } from "api/types/shop_material";
/// ACTIONS ///
import { Action } from "api/action_types/shop_material";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_material";

interface State {
  materials: IShopMaterial[],
  material: IShopMaterial,
  colors: IShopColor[],
  color: IShopColor,
  create_success: boolean,
  loading: boolean,
  error: string | null
}

const initialState = {
  materials: [],
  material: {},
  colors: [],
  color: {},
  create_success: false,
  loading: false,
  error: null
}

export const shopMaterialReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_SHOP_MATERIALS:
      return {
        ...state,
        materials: action.materials,
        loading: false,
        error: ""
      }
    case ActionType.GET_SHOP_MATERIAL:
      return {
        ...state,
        material: action.material,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_SHOP_MATERIAL_COLOR:
      return {
        ...state,
        materials: [...state.materials, action.material],
        material: action.material,
        colors: [...state.colors, action.color],
        color: action.material,
        create_success: true,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_SHOP_MATERIAL:
      return {
        ...state,
        materials: [...state.materials, action.material],
        create_success: true
      }
    case ActionType.RESET_CREATE_SHOP_MATERIAL_SUCCESS:
      return {
        ...state,
        create_success: false
      }
    case ActionType.UPDATE_SHOP_MATERIAL:
      return {
        ...state,
      }
    case ActionType.UPDATE_LOCAL_SHOP_MATERIAL:
      return {
        ...state,
        materials: [...state.materials.map(mat => mat.id === action.material.id ? action.material : mat)]
      }
    case ActionType.DELETE_SHOP_MATERIAL:
      return {
        ...state,
        materials: [...state.materials.filter(mat => mat.id !== action.id)]
      }
    case ActionType.GET_SHOP_COLORS:
      return {
        ...state,
        colors: action.colors,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_SHOP_COLOR:
      return {
        ...state,
        colors: [...state.colors, action.color]
      }
    case ActionType.UPDATE_SHOP_COLOR:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_SHOP_COLOR:
      return {
        ...state,
        colors: [...state.colors.map(color => color.id === action.color.id ? action.color : color)]
      }
    case ActionType.DELETE_SHOP_COLOR:
      return {
        ...state,
        colors: [...state.colors.filter(color => color.id !== action.id)],
        loading: false
      }
    case ActionType.CLEAR_SHOP_MATERIALS:
      return {
        ...state,
        materials: []
      }
    case ActionType.SHOP_MATERIAL_LOADING:
      return {
        ...state,
        loading: true
      }
    case ActionType.SHOP_MATERIAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
}
