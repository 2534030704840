/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { createShopColor } from "api/actions/shop_material";
import { resetCreateShopMaterialSuccess } from "api/actions/shop_material";
/// TYPES ///
import { IShopColor } from "api/types/shop_material";
import { IShopMaterial } from "api/types/shop_material";
import { IShopColorError } from "api/types/shop_material";
/// MUI ///
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import StyledRadio from "components/universal/styled_radio";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  setCurrent: (id: number) => void
}

export default function ShopCreateColor({ open, setOpen, setCurrent }: Props) {
  const dispatch = useAppDispatch();
  const { materials } = useAppSelector(state => state.shop_material);
  const { color: created_color } = useAppSelector(state => state.shop_material);
  const { create_success } = useAppSelector(state => state.shop_material);
  const { loading } = useAppSelector(state => state.shop_material);
  const [selected_material_error, setSelectMaterialError] = useState<boolean>(false);
  const [errors, setErrors] = useState<IShopColorError>({});
  const [material, setMaterial] = useState<IShopMaterial>({ taxable: true });
  const [color, setColor] = useState<IShopColor>({ one_time_use: false });

  useEffect(() => {
    if (create_success) {
      dispatch(resetCreateShopMaterialSuccess());
      setCurrent(created_color?.id ?? 0);
      setOpen(false);
    }

  }, [dispatch, setCurrent, setOpen, create_success, created_color?.id]);

  const findMaterial = (id: string) => {
    if (id === "") {
      setMaterial({});
      return;
    }

    for (let i = 0; i < materials.length; i++) {
      if (materials[i].id === Number(id)) {
        setMaterial(materials[i]);
        break;
      }
    }
  }

  const save = () => {
    let error: boolean = false;
    let errors: IShopColorError = {};

    if (!material?.id) {
      setSelectMaterialError(true);
      error = true;
    }

    if (!color?.name || color.name === "") {
      errors.name_error = "Enter a Color Name";
      errors.name_has_error = true;
      error = true;
    }

    if (!color?.width || color.width <= 0) {
      errors.width_error = "Width must be greater than 0";
      errors.width_has_error = true;
      error = true;
    }

    if (!color?.length || color.length <= 0) {
      errors.length_error = "Length must be greater than 0";
      errors.length_has_error = true;
      error = true;
    }

    setErrors(errors);

    if (error) {
      return;
    }

    dispatch(createShopColor({ ...color, material: material.id }))
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
      <DialogTitle>Add Color</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Box display="flex" flexDirection="column" sx={{ gap: "10px", paddingTop: "10px" }}>
          <TextField
            select
            SelectProps={{
              MenuProps: {
                sx: { height: "400px" },
              },
            }}
            label="Material"
            error={selected_material_error}
            helperText={selected_material_error ? "Must select a material" : ""}
            defaultValue=""
            onChange={(e) => findMaterial(e.target.value)}>
            <MenuItem key="" value=""></MenuItem>
            {
              materials.map((material: IShopMaterial) => <MenuItem key={material.id} value={material.id}>
                {`${material.name} ${getMaterialHeightDisplay(material.thickness)}`}
              </MenuItem>)
            }
          </TextField>
          <TextField
            label="Color Name"
            fullWidth
            value={color?.name ?? ""}
            error={errors?.name_has_error}
            helperText={errors?.name_error}
            onChange={e => setColor({ ...color, name: e.target.value })} />
          <TextField
            label="Price Per SQFT"
            fullWidth
            type="number"
            value={color?.price > 0 ? color.price : ""}
            error={errors?.price_has_error}
            helperText={errors?.price_error}
            onChange={e => setColor({ ...color, price: Number(e.target.value) })} />
          <TextField
            label="Length"
            fullWidth
            type="number"
            value={color?.length > 0 ? color.length : ""}
            error={errors?.length_has_error}
            helperText={errors?.length_error}
            onChange={e => setColor({ ...color, length: Number(e.target.value) })} />
          <TextField
            label="Width"
            fullWidth
            type="number"
            value={color?.width > 0 ? color.width : ""}
            error={errors?.width_has_error}
            helperText={errors?.width_error}
            onChange={e => setColor({ ...color, width: Number(e.target.value) })} />
          <FormControl sx={{ paddingLeft: "5px", marginTop: "10px" }}>
            <FormLabel id="add-customer-radios">
              <Typography variant="body2">
                Unlimited
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              value={color?.infinite ? "true" : "false"}
              aria-labelledby="infinite-radios"
              name="infinite-radios"
              onChange={e => setColor({ ...color, infinite: e.target.value === "true" ? true : false })}
            >
              <FormControlLabel
                value={"true"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    Yes
                  </Typography>
                } />
              <FormControlLabel
                value={"false"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    No
                  </Typography>
                } />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ paddingLeft: "5px", marginTop: "10px" }}>
            <FormLabel id="add-customer-radios">
              <Typography variant="body2">
                One Time Use
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              value={color?.one_time_use ? "true" : "false"}
              aria-labelledby="one-time-radios"
              name="one-time-radios"
              onChange={e => setColor({ ...color, one_time_use: e.target.value === "true" ? true : false })}
            >
              <FormControlLabel
                value={"true"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    Yes
                  </Typography>
                } />
              <FormControlLabel
                value={"false"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    No
                  </Typography>
                } />
            </RadioGroup>
          </FormControl>
          {
            !color?.one_time_use && !color?.infinite ?
              <TextField
                label="Amount (Slabs)"
                fullWidth
                value={color?.amount > 0 ? color.amount : ""}
                error={errors?.amount_has_error}
                helperText={errors?.amount_error}
                onChange={e => setColor({ ...color, amount: Number(e.target.value) })} /> :
              null
          }
        </Box>
      </DialogContent>
      <DialogActions>
        {
          loading ?
            <CircularProgress /> :
            <Fragment>
              <BaseButton text="Cancel" icon={faTimes} clickEvent={() => setOpen(false)} />
              <BaseButton text="Add" icon={faPlus} clickEvent={save} />
            </Fragment>
        }
      </DialogActions>
    </Dialog>
  )
}
