/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getAreas } from "api/actions/quotes/area";
import { clearQuote } from "api/actions/quotes/quote";
import { clearSketch } from "api/actions/sketch/clear";
import { getCounters } from "api/actions/sketch/counter";
import { getCutShapes } from "api/actions/sketch/cut_shape";
import { getLabels } from "api/actions/sketch/label";
import { getQuotePreview } from "api/actions/quotes/quote";
import { openSignatureMessage } from "api/actions/signature";
import { setBreadcrumbs } from "api/actions/ui/set";
/// DIALOGS ///
import DeleteQuote from "./dialog/delete";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import StoreColorOptionsTable from "./color_options";
import DiagramAreaCounters from "components/universal/diagrams/counter/areas";
import DiagramShapeAreas from "components/universal/diagrams/cut_shapes/areas";
import LoadingPage from "components/universal/loading_page";
import JobSite from "components/quote/quote_preview/job_site";
import PageTitle from "components/universal/page/title";
import CustomerInfo from "components/quote/quote_preview/customer_info";
import StoreEmailQuote from "./customer_info/dialog/email";
import StoreEmailSignatureRequest from "./customer_info/dialog/signature";
import QuoteDetails from "components/quote/quote_preview/details";
import QuoteSummary from "components/quote/quote_preview/summary";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPenRuler } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
/// FUNCTION ///
import { containsColorOption } from "components/quote/color_options/validate";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
import { job_url } from "components/navigation/endpoints";
import { print_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";

export default function StoreQuotePreview() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { areas } = useAppSelector(state => state.quote);
  const { areas_loaded } = useAppSelector(state => state.quote);
  const { quote } = useAppSelector(state => state.quote);
  const { quote_loaded } = useAppSelector(state => state.quote);
  const { counters } = useAppSelector(state => state.sketch);
  const { counters_loaded } = useAppSelector(state => state.sketch);
  const { cut_shapes } = useAppSelector(state => state.sketch);
  const { labels } = useAppSelector(state => state.sketch);
  const { shapes_loaded } = useAppSelector(state => state.sketch);
  const [open_delete, setOpenDelete] = useState<boolean>(false);
  const [open_signature, setOpenSignature] = useState<boolean>(false);
  const [open_send, setOpenSend] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", store_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", store_url).replace("id", id) }
    ]));
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getQuotePreview(id));
      dispatch(getCounters(id));
      dispatch(getAreas(id, null));
      dispatch(getCutShapes(id, null));
      dispatch(getLabels(id));
    }
  }, [dispatch, id]);


  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start" spacing={3}>
        <PageTitle title={quote?.name} size_lg={6} left_padding="5px" endProps={
          <Box>
            <BaseButton text="Print" icon={faPrint} clickEvent={() => window.open(`/${store_url}/${quote_url}/${detail_url}/${id}/${print_url}/`, "_blank")} />
            <BaseButton text="Draw" icon={faPenRuler} clickEvent={() => navigate(`/${store_url}/${quote_url}/${detail_url}/${id}`)} />
            <BaseButton text="Edit" icon={faEdit} clickEvent={() => navigate(`/${store_url}/${quote_url}/${detail_url}/${id}/${update_url}`)} />
            <BaseButton text="Delete" icon={faTrash} clickEvent={() => setOpenDelete(true)} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
      </Grid>
      <LoadingPage loading={!areas_loaded || !quote_loaded || !counters_loaded || !shapes_loaded}>
        <Grid container alignItems="flex-start" spacing={3}>
          <QuoteDetails quote={quote} />
          <Grid item xs={0} lg={6}></Grid>
          <CustomerInfo
            customer={quote?.customer_data}
            base_url={store_url}
            setOpenSend={setOpenSend}
            setOpenSignature={setOpenSignature}
          >
            <StoreEmailQuote open={open_send} setOpen={setOpenSend} />
            <StoreEmailSignatureRequest
              open={open_signature}
              setOpen={setOpenSignature}
              default_email={quote?.customer_data?.email}
              customer={quote?.customer ?? null}
              id={quote?.id ?? 0} />
          </CustomerInfo>
          <JobSite
            has_customer={quote?.customer_data !== null && quote?.customer_data !== undefined}
            job={quote?.job_data ?? {}}
            edit_url={`/${store_url}/${quote_url}/${detail_url}/${id}/${end_customer_url}/${quote?.customer}/${job_url}`} />
          <QuoteSummary areas={areas} quote={quote} />
          {
            containsColorOption(areas) ?
              <StoreColorOptionsTable areas={areas} /> :
              null
          }
          <DiagramAreaCounters areas={areas} counters={counters} labels={labels} counters_loaded={counters_loaded && areas_loaded} />
          <DiagramShapeAreas areas={areas} cut_shapes={cut_shapes} shapes_loaded={shapes_loaded && areas_loaded} />
        </Grid>
      </LoadingPage>
      <DeleteQuote open={open_delete} setOpen={setOpenDelete} post_del_url={`/${store_url}/${quote_url}`} />
    </motion.div >
  )
}
