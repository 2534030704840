/// REACT ///
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { createRestorePoint } from "api/actions/history";
import { updateLocalShopSink } from "api/actions/shop_quote";
import { createShopSink } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
/// TYPES ///
import { CutoutError } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopSink } from "api/types/shop_quote/sink";
import { Vector2 } from "api/types/sketch";
/// COMPONENTS ///
import SaveSink from "components/quote/drawer/sink";
/// VALUES ///
import { UNDERMOUNT } from "values/values";
import { RECTANGLE } from "values/values";
import { getShopCounterByUuid } from "functions/sketch/get/counter";
/// EMPTY ///
import { empty_sink } from "values/empty/quote/sink";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  selected_counter: IShopCounter,
  mouse: Vector2
}

export default function CreateShopSink({
  open,
  setOpen,
  selected_counter,
  mouse
}: Props) {
  const dispatch = useAppDispatch();
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const [sink, setSink] = useState<IShopSink>({ ...empty_sink });
  const [error, setError] = useState<CutoutError>({});

  const close = () => {
    setOpen(false);
    setError({});
    setSink({ ...empty_sink });
  }

  const save = () => {
    let error: CutoutError = {};
    if (!sink?.length) {
      error.length_error = "Length must be greater than 0";
      error.length_has_error = true;
    }
    if (!sink?.width) {
      error.width_error = "Width must be greater than 0";
      error.width_has_error = true;
    }

    if (error.length_has_error || error.width_has_error) {
      setError(error);
      return;
    }

    if (selected_counter) {
      dispatch(createShopSink({
        ...sink,
        counter_uuid: selected_counter?.uuid,
        uuid: v4(),
        x: Math.round(mouse.X),
        y: Math.round(mouse.Y),
        location: {
          X: Math.round(mouse.X),
          Y: Math.round(mouse.Y)
        }
      }));
      dispatch(markShopQuoteChanged());

      const new_counter: IShopCounter = getShopCounterByUuid(sink.counter_uuid, counters);

      if (new_counter) {
        dispatch(createRestorePoint(
          [
            ...counters.filter(c => c.uuid !== new_counter.uuid),
            { ...new_counter, sinks: [...new_counter.sinks.filter(s => s.uuid !== sink.uuid), sink] }
          ],
          cut_shapes,
          "Change Sink"
        ));
      }
      close();
    }
  }

  const update = (updated_sink: IShopSink) => {
    setSink(updated_sink);
    dispatch(updateLocalShopSink(updated_sink));
  }

  return (
    <SaveSink
      open={open}
      setOpen={setOpen}
      sink_type={sink?.sink_type ?? UNDERMOUNT}
      setSinkType={sink_type => update({ ...sink, sink_type })}
      shape={sink?.shape ?? RECTANGLE}
      setShape={shape => update({ ...sink, shape })}
      faucet_hole_count={sink?.faucet_hole_count ?? 1}
      setFaucetHoleCount={faucet_hole_count => update({ ...sink, faucet_hole_count })}
      length={sink?.length ?? 30}
      setLength={length => update({ ...sink, length })}
      length_error={error?.length_error ?? ""}
      length_has_error={error?.length_has_error ?? false}
      width={sink?.width ?? 26}
      setWidth={width => update({ ...sink, width })}
      width_error={error?.width_error ?? ""}
      width_has_error={error?.width_has_error ?? false}
      save={save}
      cancel={close}
      title="Create Sink Cutout" />
  );
}

