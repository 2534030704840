/// TYPES ///
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Label } from "api/types/sketch";
/// ACTIONS ///
import { Action } from "api/action_types/sketch";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/sketch";

interface State {
  cut_shapes: CutShape[],
  counters: Counter[],
  labels: Label[],
  selected_corner: Corner | null,
  selected_cut_shape: CutShape | null,
  counters_loaded: boolean,
  shapes_loaded: boolean,
  updating: boolean,
  error: string
}

const initialState = {
  cut_shapes: [],
  counters: [],
  labels: [],
  selected_corner: null,
  selected_cut_shape: null,
  counters_loaded: false,
  shapes_loaded: false,
  updating: false,
  error: ""
}

export const sketchReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload,
        counters_loaded: true
      }
    case ActionType.GET_CUT_SHAPES_SUCCESS:
      return {
        ...state,
        cut_shapes: action.payload,
        shapes_loaded: true
      }
    case ActionType.CREATE_LOCAL_COUNTER:
      return {
        ...state,
        counters: [...state.counters, action.payload]
      }
    case ActionType.UPDATE_LOCAL_COUNTER:
      return {
        ...state,
        counters: [...state.counters.filter(counter => counter.uuid !== action.payload.uuid), action.payload]
      }
    case ActionType.DELETE_LOCAL_COUNTER:
      return {
        ...state,
        counters: [...state.counters.filter(counter => counter.uuid !== action.payload)]
      }
    case ActionType.CREATE_LABEL:
      return {
        ...state,
        labels: [...state.labels, action.label],
        updating: false
      }
    case ActionType.UPDATE_LABEL:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_LABEL:
      return {
        ...state,
        labels: [...state.labels.filter(label => label.id !== action.label.id), action.label]
      }
    case ActionType.DELETE_LABEL:
      return {
        ...state,
        labels: [...state.labels.filter(label => label.id !== action.id)],
        updating: false
      }
    case ActionType.GET_LABELS:
      return {
        ...state,
        labels: action.labels
      }
    case ActionType.ADD_LOCAL_COOKTOP:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                cooktops: [...counter.cooktops, action.payload]
              })
        ]
      }
    case ActionType.UPDATE_LOCAL_COOKTOP:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                cooktops: [...counter.cooktops.filter(cooktop => cooktop.uuid !== action.payload.uuid), action.payload]
              })
        ],
      }
    case ActionType.DELETE_LOCAL_COOKTOP:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                cooktops: [...counter.cooktops.filter(cooktop => cooktop.uuid !== action.payload.uuid)]
              })
        ]
      }

    case ActionType.CREATE_LOCAL_SINK:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                sinks: [...counter.sinks, action.payload]
              })
        ]
      }
    case ActionType.UPDATE_LOCAL_SINK:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                sinks: [...counter.sinks.filter(sink => sink.uuid !== action.payload.uuid), action.payload]
              })
        ]
      }
    case ActionType.DELETE_LOCAL_SINK:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                sinks: [...counter.sinks.filter(sink => sink.uuid !== action.payload.uuid)]
              })
        ]
      }
    case ActionType.SET_SELECTED_CUT_SHAPE:
      return {
        ...state,
        selected_cut_shape: action.payload
      }
    case ActionType.DELETE_CUT_SHAPE:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.filter(cut_shape => cut_shape.uuid !== action.payload.uuid)]
      }
    case ActionType.DELETE_LOCAL_CUT_SHAPE:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.filter(cut_shape => cut_shape.counter_uuid !== action.payload)]
      }
    case ActionType.UPDATE_LOCAL_CUT_SHAPE:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.filter(cut_shape => cut_shape.uuid !== action.payload.uuid), action.payload]
      }
    case ActionType.UPDATE_LOCAL_CUT_SHAPES:
      return {
        ...state,
        cut_shapes: [
          ...state.cut_shapes.filter(cut_shape => cut_shape.counter_uuid !== action.counter_uuid), ...action.payload
        ]
      }
    case ActionType.UPDATE_LOCAL_CUT_SHAPES_BY_AREA:
      return {
        ...state,
        cut_shapes: [
          ...state.cut_shapes.filter(cut_shape => cut_shape.area_uuid !== action.area_uuid), ...action.payload
        ]
      }
    case ActionType.CREATE_LOCAL_CUT_BUMP:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.map(cut_shape => cut_shape.counter_uuid === action.bump.counter_uuid ?
          {
            ...cut_shape, cut_corners: [
              ...cut_shape.cut_corners.map(cut_corner => cut_corner.corner_uuid === action.bump.corner_uuid ?
                {
                  ...cut_corner, cut_bumps:
                    [...cut_corner.cut_bumps, action.payload]
                } :
                cut_corner
              )
            ]
          } :
          cut_shape
        )]
      }
    case ActionType.UPDATE_LOCAL_CUT_BUMP:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.map(cut_shape => cut_shape.counter_uuid === action.bump.counter_uuid ?
          {
            ...cut_shape, cut_corners: [
              ...cut_shape.cut_corners.map(cut_corner => cut_corner.corner_uuid === action.bump.corner_uuid ?
                {
                  ...cut_corner, cut_bumps:
                    [...cut_corner.cut_bumps.map(cut_bump => cut_bump.bump_uuid === action.bump.uuid ?
                      { ...cut_bump, ...action.payload } :
                      cut_bump
                    )]
                } :
                cut_corner
              )
            ]
          } :
          cut_shape
        )]
      }
    case ActionType.DELETE_LOCAL_CUT_BUMP:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.map(cut_shape => cut_shape.counter_uuid === action.payload.counter_uuid ?
          {
            ...cut_shape, cut_corners: [...cut_shape.cut_corners.map(cut_corner => cut_corner.corner_uuid === action.payload.corner_uuid ?
              { ...cut_corner, cut_bumps: [...cut_corner.cut_bumps.filter(cut_bump => cut_bump.bump_uuid !== action.payload.uuid)] } :
              cut_corner
            )]
          } :
          cut_shape
        )]
      }
    case ActionType.CREATE_LOCAL_BUMP:
      return {
        ...state,
        counters: [...state.counters.map(counter => counter.uuid === action?.payload?.counter_uuid ?
          {
            ...counter, corners: [...counter?.corners?.map(corner => corner.uuid === action?.payload?.corner_uuid ?
              { ...corner, bumps: [...corner.bumps, action.payload] } :
              corner
            )]
          } :
          counter
        )]
      }
    case ActionType.UPDATE_LOCAL_BUMP:
      return {
        ...state,
        counters: [...state.counters.map(counter => counter.uuid === action.counter_uuid ?
          {
            ...counter, corners: [...counter.corners.map(corner => corner.uuid === action.payload.corner_uuid ?
              {
                ...corner, bumps: [...corner.bumps.map(bump => bump.uuid === action.payload.uuid ?
                  action.payload :
                  bump
                )]
              } :
              corner
            )]
          } :
          counter
        )]
      }
    case ActionType.DELETE_LOCAL_BUMP:
      return {
        ...state,
        counters: [...state.counters.map(counter => counter.uuid === action.payload.counter_uuid ?
          {
            ...counter, corners: [...counter.corners.map(corner => corner.uuid === action.payload.corner_uuid ?
              { ...corner, bumps: [...corner.bumps.filter(bump => bump.uuid !== action.payload.uuid)] } :
              corner
            )]
          } :
          counter
        )]
      }
    case ActionType.CLEAR_SKETCH:
      return {
        ...state,
        counters_loaded: false,
        shapes_loaded: false,
        updating: false,
        error: ""
      }
    case ActionType.RESTORE:
      return {
        ...state,
        counters: action.counters,
        cut_shapes: action.cut_shapes
      }
    case ActionType.SKETCH_ACTION_SUCCESS:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.SKETCH_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.SKETCH_ERROR:
      return {
        ...state,
        updating: false,
        error: action.payload
      }
    default:
      return state;
  }
}
